<script>
import {mapGetters} from "vuex";

export default {
  name: "PlayerCardFront",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    order: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('game', ['myInfo', 'isShowdown', 'isShowResult', 'canPlayGame', 'bestHandDetails']),
    ...mapGetters('config', ['cardResourceNumber']),
    isCardOpen() {
      return [this.isShowdown || this.isShowResult, this.myInfo.playState === this.playState.PLAYING, this.canPlayGame].every(is => is);
    },
    bestHand() {
      if (!this.isShowResult) {
        return [];
      }

      return this.bestHandDetails ? this.bestHandDetails.cards || [] : [];
    },
    isCardInBestHand() {
      if (!this.card || !this.bestHand.length) {
        return false;
      }

      return this.bestHand.indexOf(this.card.nick) !== -1;
    },
    card() {
      if (this.order === 'second') {
        return this.myInfo.hands[1] || null;
      }

      return this.myInfo.hands[0] || null;
    },
    cardAsset() {
      if (!this.card) {
        return require('@/assets/v2/img/card_back_1.webp');
      }

      let {suit, nick} = this.card;
      const rankAbbr = nick.charAt(0) || '';
      let rank = rankAbbr.toLowerCase() === 't' ? 10 : rankAbbr.toUpperCase();

      return require(`@/assets/v2/svg/deck${this.cardResourceNumber}/${suit.toLowerCase()}_${rank}.svg`);
    },
    cardStatus() {
      if (!this.isShowResult) {
        return '';
      }

      return this.isCardInBestHand ? 'active' : 'disabled';
    }
  }
}
</script>

<template>
  <div
    class="card_front game_card"
    :class="[order, cardStatus]"
    :style="{ visibility: visible ? 'visible' : 'hidden' }"
  >
    <img :src="cardAsset" alt="">
  </div>
</template>
